import * as React from 'react';
import firebase from '@firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';

import '@rmwc/circular-progress/circular-progress.css';
import {
  CircularProgress,
  Card,
  Typography,
  CardActions,
  CardActionButtons,
  CardActionButton,
} from 'rmwc';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Shell } from './components/shell';
import { checkUserInDomain } from './services/user';

export function App() {
  const { initialising, user } = useAuthState(firebase.auth());
  const [userInDomain, setUserInDomain] = React.useState(false);
  const [checkedUserInDomain, setCheckedUserInDomain] = React.useState(false);

  React.useEffect(() => {
    checkUserInDomain(user, userInDomain, setUserInDomain).then(() =>
      setCheckedUserInDomain(true)
    );
  }, [user, checkedUserInDomain]);

  if (initialising || !checkedUserInDomain) {
    return (
      <Shell>
        <div style={{ width: '100%', textAlign: 'center', margin: '30px 0' }}>
          <CircularProgress size={72} />
        </div>
      </Shell>
    );
  }

  if (!user) {
    return <Shell>Hello</Shell>;
  }

  if (userInDomain) {
    return (
      <Switch>
        <Route path="/" render={r => <div>Hello</div>} />
      </Switch>
    );
  }

  return (
    <Shell>
      <AccountNotActivated
        activation={() => {
          setCheckedUserInDomain(false);
        }}
      />
    </Shell>
  );
}

function AccountNotActivated(props: { activation: () => void }) {
  return (
    <Card style={{ width: '100%', maxWidth: '350px', margin: '1em auto' }}>
      <div style={{ padding: '0 1rem 1rem 1rem' }}>
        <Typography use="headline6" tag="h2">
          Account not activated yet
        </Typography>
        <Typography use="body1" tag="div" theme="textSecondaryOnBackground">
          Your account has not yet been activated to use with Antipy Dashboard.
          The activation for antipy.com GSuite accounts is automatic, but can
          take up to 5 minutes. Please contact info@antipy.com to get your
          account activated otherwise.
        </Typography>
      </div>
      <CardActions>
        <CardActionButtons>
          <CardActionButton onClick={props.activation}>
            Check activation
          </CardActionButton>
          <CardActionButton onClick={() => firebase.auth().signOut()}>
            Sign out
          </CardActionButton>
        </CardActionButtons>
      </CardActions>
    </Card>
  );
}
