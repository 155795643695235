import * as React from 'react';
import firebase from '@firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';

import Container from './container';
import { version } from '../../package.json';

import {
  TopAppBarFixedAdjust,
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent,
  List,
  ListItem,
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarNavigationIcon,
  Ripple,
  Menu,
  MenuSurfaceAnchor,
  MenuItem,
  ListDivider,
  ListItemGraphic,
  ListItemPrimaryText,
  ListItemText,
} from 'rmwc';
import { checkUserInDomain } from '../services/user';

export function Shell(props: { children: React.ReactNode }) {
  return (
    <>
      <TopBar />
      <Container>{props.children}</Container>
    </>
  );
}

function TopBar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const { initialising, user } = useAuthState(firebase.auth());
  const [userInDomain, setUserInDomain] = React.useState(false);
  const [checkedUserInDomain, setCheckedUserInDomain] = React.useState(false);

  React.useEffect(() => {
    checkUserInDomain(user, userInDomain, setUserInDomain).then(() =>
      setCheckedUserInDomain(true)
    );
  }, [user]);

  return (
    <>
      <Drawer modal open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <DrawerHeader>
          <DrawerTitle>Antipy Dashboard</DrawerTitle>
          <DrawerSubtitle>v{version}</DrawerSubtitle>
        </DrawerHeader>
        <DrawerContent>
          <List>
            {!initialising && user ? (
              checkedUserInDomain ? (
                userInDomain ? (
                  <>
                    <ListItem>
                      <ListItemGraphic icon="assignment" />
                      <ListItemText>Projects</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemGraphic icon="people" />
                      <ListItemText>Clients</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemGraphic icon="apps" />
                      <ListItemText>Products</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemGraphic icon="settings" />
                      <ListItemText>Settings</ListItemText>
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem
                      onClick={() =>
                        firebase
                          .auth()
                          .signInWithRedirect(
                            new firebase.auth.GoogleAuthProvider()
                          )
                      }
                    >
                      Sign out
                    </ListItem>
                  </>
                )
              ) : null
            ) : (
              <>
                <ListItem
                  onClick={() =>
                    firebase
                      .auth()
                      .signInWithRedirect(
                        new firebase.auth.GoogleAuthProvider()
                      )
                  }
                >
                  Sign in with Google
                </ListItem>
              </>
            )}
          </List>
        </DrawerContent>
      </Drawer>
      <TopAppBar>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarNavigationIcon
              icon="menu"
              onClick={() => setDrawerOpen(true)}
            />
            <TopAppBarTitle>Antipy Dashboard</TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            {!initialising && user ? (
              <MenuSurfaceAnchor>
                <Menu
                  anchorCorner="bottomLeft"
                  open={accountMenuOpen}
                  onClose={evt => setAccountMenuOpen(false)}
                >
                  {checkedUserInDomain && userInDomain ? (
                    <>
                      <MenuItem>Account</MenuItem>
                      <ListDivider />
                    </>
                  ) : null}
                  <MenuItem onClick={() => firebase.auth().signOut()}>
                    Sign out
                  </MenuItem>
                </Menu>
                <Ripple unbounded>
                  <div
                    onClick={() => setAccountMenuOpen(true)}
                    style={{ marginRight: '8px' }}
                  >
                    <img
                      style={{ borderRadius: '50%', marginBottom: '-4px' }}
                      height="40"
                      width="40"
                      src={user.photoURL}
                    />
                  </div>
                </Ripple>
              </MenuSurfaceAnchor>
            ) : null}
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>

      <TopAppBarFixedAdjust />
    </>
  );
}
