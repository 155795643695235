import * as React from 'react';
import { render } from 'react-dom';

// Firebase
import firebase from '@firebase/app';
import '@firebase/auth';

var config = {
  apiKey: 'AIzaSyCU3awFttk0HqNzsQX_8OZbopnhr-XgelM',
  authDomain: 'antipy-dashboard.firebaseapp.com',
  databaseURL: 'https://antipy-dashboard.firebaseio.com',
  projectId: 'antipy-dashboard',
  storageBucket: 'antipy-dashboard.appspot.com',
  messagingSenderId: '367596782617',
};
firebase.initializeApp(config);

firebase.auth().getRedirectResult();

// Components
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './app';

render(
  <Router>
    <App />
  </Router>,
  document.getElementById('app')
);
